import React from 'react';
import {
  FacebookShareButton,
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import cn from 'classnames';
import Image from 'next/image';

import styles from './index.module.less';

interface ChannelItem {
  imgUrl?: string;
  channel: string;
  style?: {
    width?: number;
    height?: number;
  }
}

interface ShareProps {
  title?: string;
  url?: string;
  shareChannels?: ChannelItem[];
  className?: string
  itemClassName?: string
}

const ChannelEum = {
  whatsapp: WhatsappShareButton,
  facebook: FacebookShareButton,
  line: LineShareButton,
  telegram: TelegramShareButton,
  twitter: TwitterShareButton
}

export const Share: React.FC<ShareProps> = (props) => {
  const { title, url, shareChannels, className, itemClassName } = props;
  return (
    <div className={cn(styles.shareIcons, className)}>
      {
        shareChannels?.map((channelItem) => {
          const {
            style = {
              width: 48,
              height: 48
            }, imgUrl, channel
          } = channelItem;
          const ShareChannel = ChannelEum[channel]
          return (
            <div className={cn(styles.IconItem, itemClassName)} key={imgUrl}>
              <ShareChannel
                title={title}
                url={url}
              >
                <Image
                  src={imgUrl}
                  width={style.width}
                  height={style.height}
                  layout='fixed'
                  alt={'kikitrade'}
                />
              </ShareChannel>
            </div>
          )
        })
      }
    </div>
  );
};
